<template>
  <div>
    <br />
    <div>
      <h1>API Management</h1>
    </div>
    <!-- solaredge -->
    <v-card>
      <v-card-title>Solaredge API</v-card-title>
      <v-card-text>Manage SolarEdge API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          @click="startUploadSolaredgeFilter"
        >
          Upload Filter
        </v-btn>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="exportSiteModal('solaredge', 'site_id_only')"
          >Generate site ID list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="exportSiteModal('solaredge')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="exportInverterModal('solaredge')"
          >Generate inverter details list</v-btn>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="inverters_btn"
          @click="reloadMonthlyData('solaredge')"
        >
          Reload Monthly Data
        </v-btn>  
      </v-card-actions>
    </v-card>
    <br />
    <v-card>
      <v-card-title>Fronius API</v-card-title>
      <v-card-text>Manage Fronius API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('fronius')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('fronius')"
          >Generate inverter details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="reload_btn"
          @click="reloadMonthlyData('fronius')"
        >
          Reload Monthly Data
        </v-btn> 
      </v-card-actions>
    </v-card>
    <br />
    <v-card>
      <v-card-title>Solarlog API</v-card-title>
      <v-card-text>Manage Solarlog API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('solarlog')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('solarlog')"
          >Generate inverter details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="reload_btn"
          @click="reloadMonthlyData('solarlog')"
        >
          Reload Monthly Data
        </v-btn> 
      </v-card-actions>
    </v-card>
    <br />
    <!-- Locus -->
    <v-card>
      <v-card-title>Locus API</v-card-title>
      <v-card-text>Manage Locus API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('locus')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('locus')"
          >Generate inverter details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="inverters_btn"
          @click="reloadMonthlyData('locus')"
        >
          Reload Monthly Data
        </v-btn>
      </v-card-actions>
    </v-card>
    <br />
    <!-- Also -->
    <v-card>
      <v-card-title>Also API</v-card-title>
      <v-card-text>Manage Also API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('also')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('also')"
          >Generate inverter details list</v-btn>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="inverters_btn"
          @click="reloadMonthlyData('also')"
        >
          Reload Monthly Data
        </v-btn>  
      </v-card-actions>
    </v-card>
    <br />
    <!-- Enphase -->
    <v-card>
      <v-card-title>Enphase API</v-card-title>
      <v-card-text>Manage Enphase API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('enphase')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('enphase')"
          >Generate inverter details list</v-btn
        >
      </v-card-actions>
    </v-card>
    <br />
    <!-- Powerdash -->
    <v-card>
      <v-card-title>Powerdash API</v-card-title>
      <v-card-text>Manage Powerdash API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('powerdash')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('powerdash')"
          >Generate inverter details list</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- Powerfactors -->
    <v-card>
      <v-card-title>Powerfactors API</v-card-title>
      <v-card-text>Manage Powerfactors API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('powerfactors')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('powerfactors')"
          >Generate inverter details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="inverters_btn"
          @click="reloadMonthlyData('powerfactors')"
        >
          Reload Monthly Data
        </v-btn>
      </v-card-actions>
    </v-card>
    <br />
    <!-- SMA -->
    <v-card>
      <v-card-title>SMA API</v-card-title>
      <v-card-text>Manage SMA API options</v-card-text>
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadNoFilter('smasunnyportal')"
          >Generate site details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          text
          v-on:click="downloadInvertersNoFilter('smasunnyportal')"
          >Generate inverter details list</v-btn
        >
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="inverters_btn"
          @click="reloadMonthlyData('smasunnyportal')"
        >
          Reload Monthly Data
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showExportSiteModal" width="500">
      <v-card>
        <v-card-title>Generate Sitelist</v-card-title>
        <v-card-text>
          <v-text-field
            label="Monitoring System"
            v-model="exportMonitoringSystem"
            readonly
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-autocomplete
            v-model="filterId"
            :items="filters"
            outlined
            item-text="filter_name"
            item-value="id"
            dense
            chips
            small-chips
            label="Filter Name"
            @change="filterSelect"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-text v-if="filterSelected">
          <a href="#" @click="downloadFilter">Download filter</a>
          <br />
          <a href="#" @click="deleteFilter">Delete filter</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" outlined @click="closeExportSiteModal">
            Cancel
          </v-btn>
          <v-btn class="font-weight-bold text-xs btn-success bg-gradient-success" @click="download(exportMonitoringSystem)">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- export inverter dialog-->
    <v-dialog v-model="showExportInverterModal" width="500">
      <v-card>
        <v-card-title>Generate Inverterlist</v-card-title>
        <v-card-text>
          <v-text-field
            label="Monitoring System"
            v-model="exportMonitoringSystem"
            readonly
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-autocomplete
            v-model="filterId"
            :items="filters"
            outlined
            @change="filterSelect"
            item-text="filter_name"
            item-value="id"
            dense
            chips
            small-chips
            label="Filter Name"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" outlined @click="closeExportInverterModal">
            Cancel
          </v-btn>
          <v-btn
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            @click="downloadInverters(exportMonitoringSystem)"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- upload filter modal -->
    <v-dialog v-model="showUploadSolaredgeFilterModal" width="500">
      <v-card>
        <v-spacer>
          <v-alert
            type="success"
            dismissible
            v-model="showUploadSolaredgeFilterSuccessAlert"
          >
            Upload successful.
          </v-alert>
          <v-alert
            type="error"
            dismissible
            v-model="showUploadSolaredgeFilterErrorAlert"
          >
            Error: {{ this.uploadSolaredgeFilterError }}
          </v-alert>
        </v-spacer>
        <v-card-title>Upload Solaredge Filter</v-card-title>
        <v-card-text>
          <v-text-field
            label="Filter Name"
            placeholder="Enter filter name"
            v-model="filterName"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-file-input
            v-model="uploadedSolaredgeFilterFile"
            accept="text/csv"
            label="CSV file"
            required
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" outlined @click="closeUploadSolaredgeFilter">
            Cancel
          </v-btn>
          <v-btn class="font-weight-bold text-xs btn-success bg-gradient-success" @click="submitUploadInverter('solaredge')">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import SiteMetrics from "./SiteDetail/SiteMetrics.vue";

Vue.use(VueToast);
export default {
  name: "APIManagement",
  components: {
    SiteMetrics,
  },
  data() {
    return {
      filters: [],
      filterId: null,
      filterName: null,
      filterSelected: null,
      monitorSystem: null,
      toDateMenu: null,
      fromDateMenu: null,
      fromDateVal: null,
      toDateVal: null,
      items: ["Solaredge", "Fronius", "Solarlog", "Locus"],
      uploadedSolaredgeFilterFile: null,
      showUploadSolaredgeFilterModal: false,
      exportMonitoringSystem: null,
      exportRptId: null,
      showExportSiteModal: false,
      showExportInverterModal: false,
      showUploadSolaredgeFilterSuccessAlert: false,
      showUploadSolaredgeFilterErrorAlert: false,
      uploadSolaredgeFilterError: "",
    };
  },
  mounted() {
    document.title = "Admin"
  },
  methods: {
    initialize() {
      console.log("initialize... getFilters...");
      Service.getFilters()
        .then((response) => {
          this.filters = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading filter data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    closeUploadSolaredgeFilter() {
      this.showUploadSolaredgeFilterModal = false;
    },
    closeExportSiteModal() {
      this.showExportSiteModal = false;
      this.exportMonitoringSystem = null;
      this.exportRptId = null;
    },
    closeExportInverterModal() {
      this.showExportInverterModal = false;
      this.exportMonitoringSystem = null;
      this.exportRptId = null;
    },
    downloadFilter() {
      // NProgress.start()
      Service.downloadFilterCSVTemplate(this.filterSelected.filter_name)
        .then((response) => {
          this.handleCSVDownload(response, "filters.csv");
          NProgress.done();
        })
        .catch((error) => {
          console.log("Error getting data:", error.response);
          // NProgress.done()
        });
    },
    deleteFilter() {
      Service.deleteFilter(this.filterSelected.id)
        .then((response) => {
          Service.getFilters()
            .then((response) => {
              this.filters = response.data;
            })
            .catch((error) => {
              Vue.$toast.error(`Error loading filter data`, {
                position: "top-right",
              });
              console.error(error);
            });
        })
        .catch((error) => {
          console.log("Error getting data:", error.response);
        });
    },
    handleCSVDownload(response, filename) {
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    filterSelect(filter_id) {
      const found = this.filters.find((x) => x.id == filter_id);
      this.filterSelected = found;
      // this.casePriority = found;
    },
    dataReload() {
      var startDate = this.fromDateVal;
      var endDate = this.toDateVal;
      var system = this.monitorSystem.toLowerCase();
      const params = {
        startDate,
        endDate,
      };
      // check to make sure to is greater than or equal to from
      if (!this.compareTime(endDate, startDate)) {
        Vue.$toast.error(`Invalid date selection`, { position: "top-right" });
        return;
      }
      if (system == "locus") {
        Vue.$toast.warning(
          `Data reload for ${system} will only run for current date`,
          { position: "top-right", duration: 4000 }
        );
      }
      console.log(
        `From Date: ${startDate}, To Date: ${endDate}, System: ${system}`
      );
      Service.getSiteDataForProvider(system, params)
        .then((response) => {
          Vue.$toast.success(`Data reload queued for ${system}`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    compareTime(time1, time2) {
      return new Date(time1) >= new Date(time2); // true if time1 is later
    },
    exportSiteModal(monitoringSystem, rptId) {
      this.exportMonitoringSystem = monitoringSystem;
      this.exportRptId = rptId;
      this.showExportSiteModal = true;
    },
    exportInverterModal(monitoringSystem) {
      this.exportMonitoringSystem = monitoringSystem;
      this.showExportInverterModal = true;
    },
    reloadMonthlyData(provider_name) {
        Service.reloadMissingMonthlyDataByProvider(provider_name)
        .then((response) => {
          console.log(`reload job started for solaredge`);
          Vue.$toast.success(`Reload started.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error calling reload data:", error.response);
        });
    },
    startUploadSolaredgeFilter() {
      this.showUploadSolaredgeFilterErrorAlert = false;
      this.showUploadSolaredgeFilterSuccessAlert = false;
      this.showUploadSolaredgeFilterModal = true;
    },
    submitUploadInverter(monitoringSystem) {
      if (this.uploadedSolaredgeFilterFile) {
        Service.uploadSolaredgeFilterData(
          this.uploadedSolaredgeFilterFile,
          this.filterName,
          monitoringSystem
        )
          .then((response) => {
            Service.getFilters()
              .then((response) => {
                this.filters = response.data;
                this.handleSuccessfulSolaredgeFilterUpload(response);
                this.showUploadSolaredgeFilterModal = false;
              })
              .catch((error) => {
                Vue.$toast.error(`Error loading filter data`, {
                  position: "top-right",
                });
                console.error(error);
              });
          })
          .catch((error) => {
            this.handleErroneousSolaredgeFilterUpload(
              error.response.data.error
            );
          });
      } else {
        this.handleErroneousSolaredgeFilterUpload("No file given");
      }
    },
    handleErroneousSolaredgeFilterUpload(error) {
      this.uploadSolaredgeFilterError = error;
      this.showUploadSolaredgeFilterErrorAlert = true;
    },
    downloadInvertersNoFilter(provider_name) {
      console.log(provider_name);
      Service.getProviderInverterListCSVReportNoFilter(provider_name)
        .then((response) => {
          Vue.$toast.success(`Inverterlist generated for ${provider_name}`, {
            position: "top-right",
            duration: 4000,
          });
          Vue.$toast.info("Please check your email", {
            position: "top-right",
            duration: 4000,
            queue: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    downloadInverters(provider_name) {
      console.log(provider_name);
      Service.getProviderInverterListCSVReport(provider_name, this.filterId)
        .then((response) => {
          Vue.$toast.success(`Inverterlist generated for ${provider_name}`, {
            position: "top-right",
            duration: 4000,
          });
          this.showExportInverterModal = false;
          Vue.$toast.info("Please check your email", {
            position: "top-right",
            duration: 4000,
            queue: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    downloadNoFilter(provider_name) {
      console.log(provider_name);
      Service.getProviderSiteListCSVReportNoFilter(provider_name)
        .then((response) => {
          Vue.$toast.success(`Sitelist generated for ${provider_name}`, {
            position: "top-right",
            duration: 4000,
          });
          Vue.$toast.info("Please check your email", {
            position: "top-right",
            duration: 4000,
            queue: true,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error generating sitelist for ${provider_name}`, {
            position: "top-right",
          });
        });
    },
    download(provider_name) {
      console.log(
        `provider: ${provider_name}, filter id ${this.filterId}, rpt ${this.rptId}`
      );
      if (this.filterId == null) {
        Vue.$toast.warning(`Please Select Filter`, {
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      switch (this.exportRptId) {
        case "site_id_only":
          this.sitelistIdFilterRpt(provider_name, this.filterId);
          break;
        default:
          this.sitelistFilterRpt(provider_name, this.filterId);
      }
    },
    sitelistIdFilterRpt(provider_name, filter_id) {
      Service.getProviderIdSiteListCSVReport(provider_name, filter_id)
        .then((response) => {
          Vue.$toast.success(
            `Sitelist with IDs only generated for ${provider_name}`,
            {
              position: "top-right",
              duration: 4000,
            }
          );
          this.showExportSiteModal = false;
          Vue.$toast.info("Please check your email", {
            position: "top-right",
            duration: 4000,
            queue: true,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error generating sitelist for ${provider_name}`, {
            position: "top-right",
          });
        });
    },
    sitelistFilterRpt(provider_name, filter_id) {
      Service.getProviderSiteListCSVReport(provider_name, filter_id)
        .then((response) => {
          Vue.$toast.success(`Sitelist generated for ${provider_name}`, {
            position: "top-right",
            duration: 4000,
          });
          this.showExportSiteModal = false;
          Vue.$toast.info("Please check your email", {
            position: "top-right",
            duration: 4000,
            queue: true,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error generating sitelist for ${provider_name}`, {
            position: "top-right",
          });
        });
    },
    handleSuccessfulSolaredgeFilterUpload(response) {
      this.showUploadSolaredgeFilterSuccessAlert = true;
    },
    handleSuccessfulSitesReportExport(response) {
      this.handleCSVDownload(response, "sites_report.csv");
    },
    handleSuccessfulInvertersReportExport(response) {
      this.handleCSVDownload(response, "inverters_report.csv");
    },
    handleCSVDownload(response, filename) {
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    toDateDisp() {
      return this.toDateVal;
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss">
.v-data-table__wrapper {
  .list-cell {
    .list-cell__list-item {
      margin-bottom: 0.1rem;
    }
  }

  .percentage-cell {
    display: flex;
    align-items: center;

    .percentage-cell__percentage {
      margin: 0 0 0 0.5rem;
      width: 40%;
      display: inline-block;
      min-width: 1rem;
    }

    &.nested {
      width: 168px;

      &:not(:last-of-type) {
        margin-right: 32px;
      }
    }
  }

  .inverter-id {
    width: 100px;
    text-align: right;
    padding-right: 15px;
  }
}

.table-controls {
  text-align: center;
  width: 100%;
  margin: 0 0 20px;

  .table-controls__text {
    margin-bottom: 0;
  }

  .v-btn {
    width: 190px;
  }
}
</style>