<template>
  <div>
    <div class="d-flex-row">
      <h3 id="power_title" class="mt-6">Power and Energy</h3>
      <v-btn
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default btn-outline-default"
        color="transparent"
        id="monitoring_link_btn"
        @click="openWindow(nativeLink)"
      >
        <img :src="require('@/assets/img/' + this.system + '.png')" />
      </v-btn>
      <v-btn
        v-if="plans"
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default bg-gradient-default"
        id="site_plans_btn"
        @click="openWindow(plans)"
      >
        Site Plans
      </v-btn>
      <v-btn
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default bg-gradient-default"
        id="inverter_btn"
        @click="openWindow(`/sites/` + site_id + `/inverters_list`)"
      >
        Inverters
      </v-btn>
    </div>
    <v-tabs id="tabs_for_page">
      <v-tab @change="selectMonthlyTab()">Month</v-tab>
      <v-tab @change="selectYearlyTab()">Year</v-tab>
      <v-tab @change="selectAllTimeTab()">All Time</v-tab>
      <v-tab-item>
        <v-row class="mt-1" style="width: 800px">
          <v-col cols="3">
            <v-select
              :items="months"
              label="Select Month"
              v-model="selectedMonth"
              required
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="monthlyYears"
              label="Select Year"
              v-model="selectedMonthlyYear"
              required
            ></v-select>
          </v-col>
          <v-col class="my-auto" cols="2">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="font-weight-bold text-xs btn-default bg-gradient-default"
              @click="loadMonthlyMetrics()"
            >
              Apply</v-btn
            >
          </v-col>
          <v-col class="my-auto" cols="2">
            <v-btn
              text
              elevation="0"
              style="font-size: 9px"
              class="v-btn"
              @click="previousMonth()"
            >
              <v-icon left class="material-icons">chevron_left</v-icon>
              Prev month
            </v-btn>
          </v-col>
          <v-col class="my-auto" cols="2">
            <v-btn
              text
              elevation="0"
              style="font-size: 9px"
              @click="nextMonth()"
            >
              Next month
              <v-icon right class="material-icons">chevron_right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-row> -->
          <!-- <v-col cols="2" style="padding-bottom: 0px;padding-top: 0px;">

            <v-checkbox @change="kwhSelectSwitch" v-model="kwhSelect" label="kWh"></v-checkbox>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px;padding-top: 0px;">
            <v-checkbox @change="estimateSelectSwitch" v-model="estimateSelect" label="Estimate"></v-checkbox>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px;padding-top: 0px;">
            <v-checkbox
              @change="kwhKwpSelectSwitch"
              v-model="kwhKwpSelect"
              label="Performance"
            ></v-checkbox>
          </v-col>
          <v-col cols="3" style="padding-bottom: 0px;padding-top: 0px;">
            <v-checkbox
              @change="performanceSelectSwitch"
              v-model="performanceSelect"
              label="Normalized performance"
            ></v-checkbox>
          </v-col> -->
          <!-- <input type="checkbox" id="checkbox" v-model="kwhKwpSelect" />
            <label for="checkbox">Performance (kWh/kWP)</label>
            <input type="checkbox" id="checkbox" v-model="performanceSelect" />
            <label for="checkbox">Normalized performance</label> -->
        <!-- </v-row> -->
        <v-row>
          <v-col style="padding: 0px" cols="6">
            <apexchart              
              height="400"
              type="bar"
              :options="options"
              :series="series"
            ></apexchart>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px;">
            <v-card class="bg-gradient-light border-radius-xl">
              <v-checkbox
                class="ml-3 pt-3"
                @change="kwhSelectSwitch"
                v-model="kwhSelect"
              ><template v-slot:label><div>
                  <p
                    class="
                      text-sm
                      mb-0
                      font-weight-bold
                    "
                  >
                    kWh
                  </p>
                </div></template></v-checkbox>
              <v-checkbox
                class="ml-3"
                @change="estimateSelectSwitch"
                v-model="estimateSelect"
                ><template v-slot:label><div>
                  <p
                    class="
                      text-sm
                      mb-0
                      text-capitalize
                      font-weight-bold
                    "
                  >
                    Estimate
                  </p>
                </div></template></v-checkbox
              >
              <v-checkbox
                class="ml-3"
                @change="projectedSelectSwitch"
                v-model="projectedSelect"
              ><template v-slot:label><div>
                  <p
                    class="
                      text-sm
                      mb-0
                      text-capitalize
                      font-weight-bold
                    "
                  >
                    Projected
                  </p>
                </div></template></v-checkbox>
              <!-- <v-checkbox
                class="ml-3"
                @change="kwhKwpSelectSwitch"
                v-model="kwhKwpSelect"
              ><template v-slot:label><div>
                  <p
                    class="
                      text-sm
                      mb-0
                      text-capitalize
                      font-weight-bold
                    "
                  >
                    Performance
                  </p>
                </div></template></v-checkbox> -->
              <!-- <v-checkbox
                class="ml-3"
                @change="performanceSelectSwitch"
                v-model="performanceSelect"
              ><template v-slot:label><div>
                  <p
                    class="
                      text-sm
                      mb-0
                      text-capitalize
                      font-weight-bold
                    "
                  >
                    Normalized performance
                  </p>
                </div></template></v-checkbox> -->
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <h6>Upload monthly kwh:</h6>
        </v-row>
        <v-row style="width: 800px">
          <v-col cols="3">
            <v-select
              :items="months"
              label="Select Month"
              v-model="selectedUploadKwhMonth"
              required
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="monthlyYears"
              label="Select Year"
              v-model="selectedUploadKwhYear"
              required
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Kwh for the month"
              type="number"
              v-model="selectedUploadKwhAmount"
              :rules="rules"
            ></v-text-field>
          </v-col>
          <v-col cols="2" style="padding-top: 3%">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="font-weight-bold text-xs btn-default bg-gradient-default"
              @click="uploadMonthlyMetrics()"
              >Update Kwh</v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="mt-1" style="width: 800px">
          <v-col cols="3">
            <v-select
              :items="yearlyYears"
              label="Select Year"
              v-model="selectedYearlyYear"
              required
            ></v-select>
          </v-col>
          <!-- <v-col cols="2">
            <v-btn elevation="2" @click="loadMontlyMetrics">Apply</v-btn>
          </v-col> -->
          <v-col class="my-auto" cols="2">
            <v-btn
              text
              elevation="0"
              style="font-size: 9px"
              @click="previousYear()"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Prev year
            </v-btn>
          </v-col>
          <v-col class="my-auto" cols="2">
            <v-btn
              text
              elevation="0"
              style="font-size: 9px"
              @click="nextYear()"
            >
              Next year
              <v-icon right>mdi-arrow-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="kwhSelectSwitch"
              v-model="kwhSelect"
              label="kWh"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="estimateSelectSwitch"
              v-model="estimateSelect"
              label="Estimate"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="projectedSelectSwitch"
              v-model="projectedSelect"
              label="Projected"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="kwhKwpSelectSwitch"
              v-model="kwhKwpSelect"
              label="Performance"
            ></v-checkbox>
          </v-col>
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="performanceSelectSwitch"
              v-model="performanceSelect"
              label="Normalized performance"
            ></v-checkbox> -->
          <!-- </v-col> -->
          <!-- <v-col cols="2" style="padding-bottom: 0px;padding-top: 0px;">
            <v-checkbox @change="estimateSelectSwitch" v-model="estimateSelect" label="Estimate"></v-checkbox>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col style="padding: 0px" cols="12">
            <apexchart
              width="800"
              height="400"
              type="line"
              :options="yearlyOptions"
              :series="series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="mt-4">
          <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="kwhSelectSwitch"
              v-model="kwhSelect"
              label="kWh"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="estimateSelectSwitch"
              v-model="estimateSelect"
              label="Estimate"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="projectedSelectSwitch"
              v-model="projectedSelect"
              label="Projected"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="kwhKwpSelectSwitch"
              v-model="kwhKwpSelect"
              label="Performance"
            ></v-checkbox>
          </v-col>
          <v-col cols="3" style="padding-bottom: 0px; padding-top: 0px">
            <v-checkbox
              @change="performanceSelectSwitch"
              v-model="performanceSelect"
              label="Normalized performance"
            ></v-checkbox>
          </v-col> -->

          <!-- <v-col cols="3" style="padding-bottom: 0px;padding-top: 0px;">
            <v-checkbox @change="performanceSelectSwitch" v-model="performanceSelect" label="Normalized performance"></v-checkbox>
          </v-col>
          <v-col cols="2" style="padding-bottom: 0px;padding-top: 0px;">
            <v-checkbox @change="estimateSelectSwitch" v-model="estimateSelect" label="Estimate"></v-checkbox>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <apexchart
              width="800"
              height="400"
              type="bar"
              :options="allTimeOptions"
              :series="series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
    <br />
    <!-- <comparative-energy v-bind:site_id="this.site_id"></comparative-energy> -->
  </div>
</template>

<script>
// import InverterList from "./InverterList.vue";
import Service from "@/services/Service.js";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import ComparativeEnergy from "./ComparativeEnergy.vue";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  components: {
    ComparativeEnergy,
  },
  props: ["site_id", "nativeLink", "system", "plans"],
  data() {
    return {
      sites: [],
      kwhSelect: true,
      kwhKwpSelect: false,
      projectedSelect: false,
      performanceSelect: false,
      estimateSelect: true,
      selectedMonthlyYear: null,
      selectedYearlyYear: null,
      selectedMonth: null,
      selectedUploadKwhYear: null,
      selectedUploadKwhMonth: null,
      selectedUploadKwhAmount: null,
      selectedTab: "month",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      monthlyYears: [],
      yearlyYears: [2021, 2020],
      allTimeOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: true,
              offsetY: 30,
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        chart: {
          id: "all-time-data",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: true,
              offsetY: 30,
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        chart: {
          id: "site-data",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      yearlyOptions: {
        chart: {
          id: "yearly-data",
          type: "line",
        },
        stroke: {
          curve: "smooth",
          width: [0, 4],
        },
        markers: {
          size: 0,
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "kWh",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
        {
          name: "normalized performance",
          data: [20, 30, 35, 40, 39, 50, 60, 81],
        },
        {
          name: "Performance (kWh/kWP)",
          data: [10, 20, 25, 30, 29, 40, 50, 71],
        },
      ],
      headers: [
        {
          text: "Site Name",
          value: "name",
          width: "80px",
        },
        {
          text: "Solar Company",
          value: "customerName",
          width: "100px",
        },
        {
          text: "Account",
          value: "customerAccountName",
          width: "100px",
        },
        {
          text: "",
          value: "monitoringSystem",
          width: 0,
          sortable: false,
        },
        {
          text: "Past 30 Days' System Health",
          value: "metrics.site.past30Days.systemHealth",
          width: 200,
        },
        {
          text: "Day's System Health",
          value: "metrics.site.day.systemHealth",
          width: 200,
        },
        {
          text: "Past 30 Days' Performance",
          value: "metrics.site.past30Days.performance",
        },
        {
          text: "Day's Performance",
          value: "metrics.site.day.performance",
        },
        {
          text: "Zip Code",
          value: "address.postalCode",
        },
        {
          text: "Possible Solutions",
          value: "possibleSolutions",
        },
        {
          text: "",
          value: "blueprintIconPlaceholder",
        },
        {
          text: "",
          value: "nativePlatformLinkIconPlaceholder",
        },
        {
          text: "",
          value: "emailCustomerIconPlaceholder",
        },
        {
          text: "",
          value: "addNoteIconPlaceholder",
        },
        {
          text: "",
          value: "addCaseIconPlaceholder",
        },
      ],
      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    initialize(monthStr, year) {
      this.selectedMonthlyYear = year;
      this.selectedMonth = monthStr;
      this.selectedUploadKwhYear = year;
      this.selectedUploadKwhMonth = monthStr;
      console.log(
        `initialize...site_id: ${this.site_id} for ${monthStr}, ${year}`
      );
      this.loadMetricYears();
      this.loadMonthlyMetrics();
    },
    kwhSelectSwitch(newValue, oldValue) {
      this.kwhSelect = newValue;
      if (this.selectedTab == "month") {
        this.loadMontlhyMetrics();
      } else if (this.selectedTab == "year") {
        this.loadYearlyMetrics();
      } else {
        this.loadAllTimeMetrics();
      }
    },
    projectedSelectSwitch(newValue, oldValue) {
      this.projectedSelect = newValue;
      if (this.selectedTab == "month") {
        this.loadMonthlyMetrics();
      } else if (this.selectedTab == "year") {
        this.loadYearlyMetrics();
      } else {
        this.loadAllTimeMetrics();
      }
    },
    // kwhKwpSelectSwitch(newValue, oldValue) {
    //   this.kwhKwpSelect = newValue;
    //   if (this.selectedTab == "month") {
    //     this.loadMonthlyMetrics();
    //   } else if (this.selectedTab == "year") {
    //     this.loadYearlyMetrics();
    //   } else {
    //     this.loadAllTimeMetrics();
    //   }
    // },
    estimateSelectSwitch(newValue, oldValue) {
      this.estimateSelect = newValue;
      if (this.selectedTab == "month") {
        this.loadMonthlyMetrics();
      } else if (this.selectedTab == "year") {
        this.loadYearlyMetrics();
      } else {
        this.loadAllTimeMetrics();
      }
    },
    // performanceSelectSwitch(newValue, oldValue) {
    //   this.performanceSelect = newValue;
    //   if (this.selectedTab == "month") {
    //     this.loadMonthlyMetrics();
    //   } else if (this.selectedTab == "year") {
    //     this.loadYearlyMetrics();
    //   } else {
    //     this.loadAllTimeMetrics();
    //   }
    // },
    loadMetricYears() {
      Service.getSiteMetricYears(this.site_id)
        .then((response) => {
          if (response.data.years.length > 0) {
            this.monthlyYears = response.data.years;
            this.yearlyYears = response.data.years;
          } else {
            this.monthlyYears = this.$data.monthlyYears;
            this.yearlyYears = this.$data.yearlyYears;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadAllTimeMetrics() {
      NProgress.start();
      var that = this;
      Service.getSiteCompEnergyYear(this.site_id)
        .then((response) => {
          console.log(response.data);
          let newXAxis = response.data.alltime_data.map((d) => {
            return d.year;
          });
          console.log(newXAxis);
          this.allTimeOptions = {
            xaxis: {
              categories: newXAxis,
            },
          };
          // let s = response.data.alltime_data.map((d) => {
          //   return {
          //     name: `${d.year.toString()}`,
          //     type: 'column',
          //     data: [d.kwh]
          //   }
          // })
          // this.series = [...s];
          let kwhData = response.data.alltime_data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return d.kwh;
          });
          // let perfData = response.data.alltime_data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return d.performance;
          // });
          let projectedData = response.data.alltime_data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return d.kwh_kwp; // change this to d.proj?
          });
          // let kwhKwpData = response.data.alltime_data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return d.kwh_kwp;
          // });
          let kwhEstimateData = response.data.alltime_data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return d.kwh_estimate;
          });
          this.series = this.renderNewSeries(
            kwhData,
            // perfData,
            projectedData,
            // kwhKwpData,
            kwhEstimateData
          );
          NProgress.done();
        })
        .catch((error) => {
          console.error(error);
          NProgress.done();
        });
    },
    loadMonthlyMetrics() {
      let year = this.selectedMonthlyYear;
      let month = this.getMonthFromString(this.selectedMonth);
      NProgress.start();
      Service.getSiteMonthlyStats(this.site_id, month, year)
        .then((response) => {
          // this.sites = response.data.sites;
          console.log(response.data);
          let newXAxis = response.data.map((d) => {
            var d = new Date(`${d.date} 00:00:00`);
            return d.getDate();
          });
          console.log(newXAxis);
          this.options = {
            chart: {
              id: "monthly-stats",
            },
            xaxis: {
              categories: newXAxis,
            },
          };
          let kwhData = response.data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh).toFixed(1);
          });
          let kwhEstimateData = response.data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh_estimate).toFixed(1);
          });
          let projectedData = response.data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh_estimate).toFixed(1); //change to proj
          });
          // let perfData = response.data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return parseFloat(d.performance).toFixed(1);
          // });
          // let kwhKwpData = response.data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return parseFloat(d.kwh_kwp).toFixed(1);
          // });
          this.series = this.renderNewSeries(
            kwhData,
            projectedData,
            // perfData,
            // kwhKwpData,
            kwhEstimateData
          );
          NProgress.done();
        })
        .catch((error) => {
          //   NProgress.done();
          // Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    uploadMonthlyMetrics() {
      let year = this.selectedUploadKwhYear;
      let month = this.getMonthFromString(this.selectedUploadKwhMonth);
      let month_kwh_amount = this.selectedUploadKwhAmount;

      if (month_kwh_amount) {
        Service.updateSiteMonthlyKwh(
          this.site_id,
          month,
          year,
          month_kwh_amount
        )
          .then((response) => {
            console.log("Monthly KWH successfully updated");
            console.log(response);
            this.loadMonthlyMetrics();
          })
          .catch((error) => {
            console.log("Monthly KWH not updated");
            console.error(error);
          });
      }
    },
    loadYearlyMetrics() {
      let year = this.selectedYearlyYear;
      NProgress.start();
      var that = this;
      Service.getSiteYearlyComboStats(this.site_id, year)
        .then(function (values) {
          console.log(values);
          let yearlyData = values[0].data;
          let s_comp = values[1].data;
          var siteCompEnergy = s_comp.filter(function (d) {
            return d.year != that.selectedYearlyYear;
          });
          let newXAxis = yearlyData.map((d) => {
            var d = new Date(`${d.date} 00:00:00`);
            return that.getMonthStrFromInt(d.getMonth());
          });
          console.log(newXAxis);
          that.yearlyOptions = {
            xaxis: {
              categories: newXAxis,
            },
          };

          let kwhData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh).toFixed(1);
          });
          let perfData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.performance).toFixed(1);
          });
          let kwhKwpData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh_kwp).toFixed(1);
          });
          let kwhEstimateData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh_estimate).toFixed(1);
          });
          // this.series = this.renderSeries(kwhData, perfData, kwhKwpData)
          let s = that.renderNewSeries(
            kwhData,
            perfData,
            kwhKwpData,
            kwhEstimateData
          );
          for (let i = 0; i < siteCompEnergy.length; i++) {
            let kwhData = siteCompEnergy[i].data.map((k) => {
              return k.kwh === null ? 0 : parseFloat(k.kwh).toFixed(1);
            });
            s.push({
              name: `${siteCompEnergy[i].year.toString()}`,
              type: "line",
              data: kwhData,
            });
          }
          that.series = s;
          NProgress.done();
        })
        .catch((error) => {
          console.error(error);
          NProgress.done();
        });
    },
    renderNewSeries(kwhData, kwhEstimateData, projectedData) {
      // insert series as needed
      let d = [];
      if (this.kwhSelect) {
        d.push({
          name: "kWh",
          type: "column",
          data: kwhData,
        });
      }
      // if (this.performanceSelect) {
      //   d.push({
      //     name: "Normalized performance",
      //     type: "column",
      //     data: perfData,
      //   });
      // }
      if (this.projectedSelect) {
        d.push({
          name: "Projected",
          type: "column",
          data: projectedData,
        });
      }
      // if (this.kwhKwpSelect) {
      //   d.push({
      //     name: "Performance (kWh/kWP)",
      //     type: "column",
      //     data: kwhKwpData,
      //   });
      // }
      if (this.estimateSelect) {
        d.push({
          name: "kWh Estimate",
          type: "column",
          data: kwhEstimateData,
        });
      }
      return [...d];
    },
    nextMonth() {
      let year = this.selectedMonthlyYear;
      let month = this.getMonthFromString(this.selectedMonth);
      var newDate = new Date(Date.parse(month + " 1, " + year));
      newDate.setMonth(newDate.getMonth() + 1);
      console.log(newDate);
      // set month and year, then load data
      this.selectedMonthlyYear = newDate.getFullYear();
      this.selectedMonth = this.getMonthStrFromInt(newDate.getMonth());
      this.loadMonthlyMetrics();
    },
    nextYear() {
      let year = this.selectedYearlyYear;

      var newDate = new Date(Date.parse("1," + " 1, " + year));
      newDate.setFullYear(newDate.getFullYear() + 1);
      console.log(newDate);
      // set month and year, then load data
      this.selectedYearlyYear = newDate.getFullYear();
      this.loadYearlyMetrics();
    },
    previousMonth() {
      let year = this.selectedMonthlyYear;
      let month = this.getMonthFromString(this.selectedMonth);
      var newDate = new Date(Date.parse(month + " 1, " + year));
      newDate.setMonth(newDate.getMonth() - 1);
      console.log(newDate);
      console.log(this.kwhSelect);
      // set month and year, then load data
      this.selectedMonthlyYear = newDate.getFullYear();
      this.selectedMonth = this.getMonthStrFromInt(newDate.getMonth());
      this.loadMonthlyMetrics();
    },
    previousYear() {
      let year = this.selectedYearlyYear;
      var newDate = new Date(Date.parse("1," + " 1, " + year));
      newDate.setFullYear(newDate.getFullYear() - 1);
      console.log(newDate);
      // set month and year, then load data
      this.selectedYearlyYear = newDate.getFullYear();
      this.loadYearlyMetrics();
    },
    selectMonthlyTab() {
      console.log("selected monthly tab...");
      this.selectedTab = "month";
    },
    selectYearlyTab() {
      console.log("selected yearly tab...");
      this.selectedTab = "year";
      var d = new Date();
      var y = d.getFullYear();
      // init year
      this.selectedYearlyYear = y;
      this.loadYearlyMetrics();
    },
    selectAllTimeTab() {
      this.selectedTab = "alltime";
      this.loadAllTimeMetrics();
    },
    getMonthFromString(mon) {
      return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
    },
    getMonthStrFromInt(m) {
      return this.months[m];
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
  },
  computed: {},
  created() {
    //   init with current month/year
    var d = new Date();
    var m = d.getMonth();
    var y = d.getFullYear();
    this.initialize(this.getMonthStrFromInt(m), y);
  },
};
</script>

<style>
#power_title {
  margin: 0;
  display: inline-block;
}
#monitoring_link_btn {
  /* float: left; */
  margin-left: 2%;
}
#site_plans_btn {
  /* float: left; */
  margin-left: 1%;
}
#inverter_btn {
  margin-left: 1%;
}
#tabs_for_page {
  padding-top: 1em;
}
</style>
