var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._m(0),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Solaredge API")]),
          _c("v-card-text", [_vm._v("Manage SolarEdge API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: { click: _vm.startUploadSolaredgeFilter },
                },
                [_vm._v(" Upload Filter ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.exportSiteModal("solaredge", "site_id_only")
                    },
                  },
                },
                [_vm._v("Generate site ID list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.exportSiteModal("solaredge")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.exportInverterModal("solaredge")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "inverters_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData("solaredge")
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Fronius API")]),
          _c("v-card-text", [_vm._v("Manage Fronius API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("fronius")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("fronius")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "reload_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData("fronius")
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Solarlog API")]),
          _c("v-card-text", [_vm._v("Manage Solarlog API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("solarlog")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("solarlog")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "reload_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData("solarlog")
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Locus API")]),
          _c("v-card-text", [_vm._v("Manage Locus API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("locus")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("locus")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "inverters_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData("locus")
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Also API")]),
          _c("v-card-text", [_vm._v("Manage Also API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("also")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("also")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "inverters_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData("also")
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Enphase API")]),
          _c("v-card-text", [_vm._v("Manage Enphase API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("enphase")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("enphase")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Powerdash API")]),
          _c("v-card-text", [_vm._v("Manage Powerdash API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("powerdash")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("powerdash")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Powerfactors API")]),
          _c("v-card-text", [_vm._v("Manage Powerfactors API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("powerfactors")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("powerfactors")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "inverters_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData("powerfactors")
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("SMA API")]),
          _c("v-card-text", [_vm._v("Manage SMA API options")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadNoFilter("smasunnyportal")
                    },
                  },
                },
                [_vm._v("Generate site details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadInvertersNoFilter("smasunnyportal")
                    },
                  },
                },
                [_vm._v("Generate inverter details list")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "inverters_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData("smasunnyportal")
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showExportSiteModal,
            callback: function ($$v) {
              _vm.showExportSiteModal = $$v
            },
            expression: "showExportSiteModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Generate Sitelist")]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Monitoring System", readonly: "" },
                    model: {
                      value: _vm.exportMonitoringSystem,
                      callback: function ($$v) {
                        _vm.exportMonitoringSystem = $$v
                      },
                      expression: "exportMonitoringSystem",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.filters,
                      outlined: "",
                      "item-text": "filter_name",
                      "item-value": "id",
                      dense: "",
                      chips: "",
                      "small-chips": "",
                      label: "Filter Name",
                    },
                    on: { change: _vm.filterSelect },
                    model: {
                      value: _vm.filterId,
                      callback: function ($$v) {
                        _vm.filterId = $$v
                      },
                      expression: "filterId",
                    },
                  }),
                ],
                1
              ),
              _vm.filterSelected
                ? _c("v-card-text", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: { click: _vm.downloadFilter },
                      },
                      [_vm._v("Download filter")]
                    ),
                    _c("br"),
                    _c(
                      "a",
                      { attrs: { href: "#" }, on: { click: _vm.deleteFilter } },
                      [_vm._v("Delete filter")]
                    ),
                  ])
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", outlined: "" },
                      on: { click: _vm.closeExportSiteModal },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-success bg-gradient-success",
                      on: {
                        click: function ($event) {
                          return _vm.download(_vm.exportMonitoringSystem)
                        },
                      },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showExportInverterModal,
            callback: function ($$v) {
              _vm.showExportInverterModal = $$v
            },
            expression: "showExportInverterModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Generate Inverterlist")]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Monitoring System", readonly: "" },
                    model: {
                      value: _vm.exportMonitoringSystem,
                      callback: function ($$v) {
                        _vm.exportMonitoringSystem = $$v
                      },
                      expression: "exportMonitoringSystem",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.filters,
                      outlined: "",
                      "item-text": "filter_name",
                      "item-value": "id",
                      dense: "",
                      chips: "",
                      "small-chips": "",
                      label: "Filter Name",
                    },
                    on: { change: _vm.filterSelect },
                    model: {
                      value: _vm.filterId,
                      callback: function ($$v) {
                        _vm.filterId = $$v
                      },
                      expression: "filterId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", outlined: "" },
                      on: { click: _vm.closeExportInverterModal },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-success bg-gradient-success",
                      on: {
                        click: function ($event) {
                          return _vm.downloadInverters(
                            _vm.exportMonitoringSystem
                          )
                        },
                      },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showUploadSolaredgeFilterModal,
            callback: function ($$v) {
              _vm.showUploadSolaredgeFilterModal = $$v
            },
            expression: "showUploadSolaredgeFilterModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-spacer",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: { type: "success", dismissible: "" },
                      model: {
                        value: _vm.showUploadSolaredgeFilterSuccessAlert,
                        callback: function ($$v) {
                          _vm.showUploadSolaredgeFilterSuccessAlert = $$v
                        },
                        expression: "showUploadSolaredgeFilterSuccessAlert",
                      },
                    },
                    [_vm._v(" Upload successful. ")]
                  ),
                  _c(
                    "v-alert",
                    {
                      attrs: { type: "error", dismissible: "" },
                      model: {
                        value: _vm.showUploadSolaredgeFilterErrorAlert,
                        callback: function ($$v) {
                          _vm.showUploadSolaredgeFilterErrorAlert = $$v
                        },
                        expression: "showUploadSolaredgeFilterErrorAlert",
                      },
                    },
                    [
                      _vm._v(
                        " Error: " +
                          _vm._s(this.uploadSolaredgeFilterError) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-card-title", [_vm._v("Upload Solaredge Filter")]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Filter Name",
                      placeholder: "Enter filter name",
                    },
                    model: {
                      value: _vm.filterName,
                      callback: function ($$v) {
                        _vm.filterName = $$v
                      },
                      expression: "filterName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-file-input", {
                    attrs: {
                      accept: "text/csv",
                      label: "CSV file",
                      required: "",
                    },
                    model: {
                      value: _vm.uploadedSolaredgeFilterFile,
                      callback: function ($$v) {
                        _vm.uploadedSolaredgeFilterFile = $$v
                      },
                      expression: "uploadedSolaredgeFilterFile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", outlined: "" },
                      on: { click: _vm.closeUploadSolaredgeFilter },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-success bg-gradient-success",
                      on: {
                        click: function ($event) {
                          return _vm.submitUploadInverter("solaredge")
                        },
                      },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", [_vm._v("API Management")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }