var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex-row" },
        [
          _c("h3", { staticClass: "mt-6", attrs: { id: "power_title" } }, [
            _vm._v("Power and Energy"),
          ]),
          _c(
            "v-btn",
            {
              staticClass:
                "font-weight-bold text-xs btn-default btn-outline-default",
              attrs: {
                ripple: false,
                elevation: 0,
                color: "transparent",
                id: "monitoring_link_btn",
              },
              on: {
                click: function ($event) {
                  return _vm.openWindow(_vm.nativeLink)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/img/" + this.system + ".png") },
              }),
            ]
          ),
          _vm.plans
            ? _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "site_plans_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.openWindow(_vm.plans)
                    },
                  },
                },
                [_vm._v(" Site Plans ")]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass:
                "font-weight-bold text-xs btn-default bg-gradient-default",
              attrs: { ripple: false, elevation: 0, id: "inverter_btn" },
              on: {
                click: function ($event) {
                  return _vm.openWindow(
                    "/sites/" + _vm.site_id + "/inverters_list"
                  )
                },
              },
            },
            [_vm._v(" Inverters ")]
          ),
        ],
        1
      ),
      _c(
        "v-tabs",
        { attrs: { id: "tabs_for_page" } },
        [
          _c(
            "v-tab",
            {
              on: {
                change: function ($event) {
                  return _vm.selectMonthlyTab()
                },
              },
            },
            [_vm._v("Month")]
          ),
          _c(
            "v-tab",
            {
              on: {
                change: function ($event) {
                  return _vm.selectYearlyTab()
                },
              },
            },
            [_vm._v("Year")]
          ),
          _c(
            "v-tab",
            {
              on: {
                change: function ($event) {
                  return _vm.selectAllTimeTab()
                },
              },
            },
            [_vm._v("All Time")]
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { staticClass: "mt-1", staticStyle: { width: "800px" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.months,
                          label: "Select Month",
                          required: "",
                        },
                        model: {
                          value: _vm.selectedMonth,
                          callback: function ($$v) {
                            _vm.selectedMonth = $$v
                          },
                          expression: "selectedMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.monthlyYears,
                          label: "Select Year",
                          required: "",
                        },
                        model: {
                          value: _vm.selectedMonthlyYear,
                          callback: function ($$v) {
                            _vm.selectedMonthlyYear = $$v
                          },
                          expression: "selectedMonthlyYear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "my-auto", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-bold text-xs btn-default bg-gradient-default",
                          attrs: { ripple: false, elevation: 0 },
                          on: {
                            click: function ($event) {
                              return _vm.loadMonthlyMetrics()
                            },
                          },
                        },
                        [_vm._v(" Apply")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "my-auto", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "v-btn",
                          staticStyle: { "font-size": "9px" },
                          attrs: { text: "", elevation: "0" },
                          on: {
                            click: function ($event) {
                              return _vm.previousMonth()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-icons",
                              attrs: { left: "" },
                            },
                            [_vm._v("chevron_left")]
                          ),
                          _vm._v(" Prev month "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "my-auto", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "font-size": "9px" },
                          attrs: { text: "", elevation: "0" },
                          on: {
                            click: function ($event) {
                              return _vm.nextMonth()
                            },
                          },
                        },
                        [
                          _vm._v(" Next month "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-icons",
                              attrs: { right: "" },
                            },
                            [_vm._v("chevron_right")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticStyle: { padding: "0px" }, attrs: { cols: "6" } },
                    [
                      _c("apexchart", {
                        attrs: {
                          height: "400",
                          type: "bar",
                          options: _vm.options,
                          series: _vm.series,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                      },
                      attrs: { cols: "2" },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "bg-gradient-light border-radius-xl" },
                        [
                          _c("v-checkbox", {
                            staticClass: "ml-3 pt-3",
                            on: { change: _vm.kwhSelectSwitch },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "\n                    text-sm\n                    mb-0\n                    font-weight-bold\n                  ",
                                        },
                                        [_vm._v(" kWh ")]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.kwhSelect,
                              callback: function ($$v) {
                                _vm.kwhSelect = $$v
                              },
                              expression: "kwhSelect",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "ml-3",
                            on: { change: _vm.estimateSelectSwitch },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "\n                    text-sm\n                    mb-0\n                    text-capitalize\n                    font-weight-bold\n                  ",
                                        },
                                        [_vm._v(" Estimate ")]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.estimateSelect,
                              callback: function ($$v) {
                                _vm.estimateSelect = $$v
                              },
                              expression: "estimateSelect",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "ml-3",
                            on: { change: _vm.projectedSelectSwitch },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "\n                    text-sm\n                    mb-0\n                    text-capitalize\n                    font-weight-bold\n                  ",
                                        },
                                        [_vm._v(" Projected ")]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.projectedSelect,
                              callback: function ($$v) {
                                _vm.projectedSelect = $$v
                              },
                              expression: "projectedSelect",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-row", { staticClass: "mx-0" }, [
                _c("h6", [_vm._v("Upload monthly kwh:")]),
              ]),
              _c(
                "v-row",
                { staticStyle: { width: "800px" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.months,
                          label: "Select Month",
                          required: "",
                        },
                        model: {
                          value: _vm.selectedUploadKwhMonth,
                          callback: function ($$v) {
                            _vm.selectedUploadKwhMonth = $$v
                          },
                          expression: "selectedUploadKwhMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.monthlyYears,
                          label: "Select Year",
                          required: "",
                        },
                        model: {
                          value: _vm.selectedUploadKwhYear,
                          callback: function ($$v) {
                            _vm.selectedUploadKwhYear = $$v
                          },
                          expression: "selectedUploadKwhYear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Kwh for the month",
                          type: "number",
                          rules: _vm.rules,
                        },
                        model: {
                          value: _vm.selectedUploadKwhAmount,
                          callback: function ($$v) {
                            _vm.selectedUploadKwhAmount = $$v
                          },
                          expression: "selectedUploadKwhAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "padding-top": "3%" },
                      attrs: { cols: "2" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-bold text-xs btn-default bg-gradient-default",
                          attrs: { ripple: false, elevation: 0 },
                          on: {
                            click: function ($event) {
                              return _vm.uploadMonthlyMetrics()
                            },
                          },
                        },
                        [_vm._v("Update Kwh")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { staticClass: "mt-1", staticStyle: { width: "800px" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.yearlyYears,
                          label: "Select Year",
                          required: "",
                        },
                        model: {
                          value: _vm.selectedYearlyYear,
                          callback: function ($$v) {
                            _vm.selectedYearlyYear = $$v
                          },
                          expression: "selectedYearlyYear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "my-auto", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "font-size": "9px" },
                          attrs: { text: "", elevation: "0" },
                          on: {
                            click: function ($event) {
                              return _vm.previousYear()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-arrow-left"),
                          ]),
                          _vm._v(" Prev year "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "my-auto", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "font-size": "9px" },
                          attrs: { text: "", elevation: "0" },
                          on: {
                            click: function ($event) {
                              return _vm.nextYear()
                            },
                          },
                        },
                        [
                          _vm._v(" Next year "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-arrow-right "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                      },
                      attrs: { cols: "2" },
                    },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "kWh" },
                        on: { change: _vm.kwhSelectSwitch },
                        model: {
                          value: _vm.kwhSelect,
                          callback: function ($$v) {
                            _vm.kwhSelect = $$v
                          },
                          expression: "kwhSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                      },
                      attrs: { cols: "2" },
                    },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Estimate" },
                        on: { change: _vm.estimateSelectSwitch },
                        model: {
                          value: _vm.estimateSelect,
                          callback: function ($$v) {
                            _vm.estimateSelect = $$v
                          },
                          expression: "estimateSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                      },
                      attrs: { cols: "2" },
                    },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Projected" },
                        on: { change: _vm.projectedSelectSwitch },
                        model: {
                          value: _vm.projectedSelect,
                          callback: function ($$v) {
                            _vm.projectedSelect = $$v
                          },
                          expression: "projectedSelect",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticStyle: { padding: "0px" }, attrs: { cols: "12" } },
                    [
                      _c("apexchart", {
                        attrs: {
                          width: "800",
                          height: "400",
                          type: "line",
                          options: _vm.yearlyOptions,
                          series: _vm.series,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                      },
                      attrs: { cols: "2" },
                    },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "kWh" },
                        on: { change: _vm.kwhSelectSwitch },
                        model: {
                          value: _vm.kwhSelect,
                          callback: function ($$v) {
                            _vm.kwhSelect = $$v
                          },
                          expression: "kwhSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                      },
                      attrs: { cols: "2" },
                    },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Estimate" },
                        on: { change: _vm.estimateSelectSwitch },
                        model: {
                          value: _vm.estimateSelect,
                          callback: function ($$v) {
                            _vm.estimateSelect = $$v
                          },
                          expression: "estimateSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-bottom": "0px",
                        "padding-top": "0px",
                      },
                      attrs: { cols: "2" },
                    },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Projected" },
                        on: { change: _vm.projectedSelectSwitch },
                        model: {
                          value: _vm.projectedSelect,
                          callback: function ($$v) {
                            _vm.projectedSelect = $$v
                          },
                          expression: "projectedSelect",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("apexchart", {
                        attrs: {
                          width: "800",
                          height: "400",
                          type: "bar",
                          options: _vm.allTimeOptions,
                          series: _vm.series,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }